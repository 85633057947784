import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prd';
import {
  DEFAULT_LANGUAGE,
  Language,
  systemLanguage,
  systemLanguageDOM,
} from '../models/supported-language.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly translate = inject(TranslateService);
  private readonly document = inject(DOCUMENT);

  private currentLang$ = new BehaviorSubject<string>(
    environment.defaultLanguage
  ); // Default language

  private langPath = '/assets/i18n/';

  constructor(private http: HttpClient) {}

  setCurrentLang(lang: string): void {
    if (lang !== this.currentLang$?.value && lang !== '') {
      this.setDocumentLanguage(lang);
      const systemLanguage = this.getSystemLanguageName(lang);
      this.currentLang$.next(systemLanguage);
      this.translate.use(systemLanguage);
    }
  }

  getCurrentLang(): Observable<string> {
    return this.currentLang$.asObservable();
  }

  getCurrentLanguageCode(): string {
    const lang = this.currentLang$.value;
    return lang.substring(0, 2).toUpperCase();
  }

  loadLanguageFile(lang: string): Observable<unknown> {
    const systemLanguage = this.getSystemLanguageName(lang);
    return this.http.get(`${this.langPath}${systemLanguage}.json`);
  }

  getSystemLanguageName(preferredLanguage: string) {
    const translationLanguage =
      (Object.keys(systemLanguage).find((value: string) =>
        preferredLanguage?.startsWith(value)
      ) as Language) ?? DEFAULT_LANGUAGE;
    return systemLanguage[translationLanguage];
  }

  getTranslationLanguage(preferredLanguage: string): Language {
    return (
      (Object.keys(systemLanguage).find((value: string) =>
        preferredLanguage?.startsWith(value)
      ) as Language) ?? DEFAULT_LANGUAGE
    );
  }

  getHTMLTagLanguageName(preferredLanguage: string) {
    return systemLanguageDOM[this.getTranslationLanguage(preferredLanguage)];
  }

  // Sets the language of the document <html> tag
  private setDocumentLanguage(lang: string): void {
    this.document.documentElement.lang = this.getHTMLTagLanguageName(lang);
  }
}
