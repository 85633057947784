import { BrowserCacheLocation } from '@azure/msal-browser';
import { Client } from '../app/core/models/client.model';
import { environmentCommon } from './environment.common';
import {
  CoreEnvironmentModel,
  EnvironmentModel,
  LegislationLinksEnvironmentModel,
  MsalConfigEnvironmentModel,
  ToolLinksEnvironmentModel,
} from './environment.model';
import { EnvironmentName } from './util/environment.name';

const environmentCore: CoreEnvironmentModel = {
  name: EnvironmentName.stg,
  production: false,
  apiUrl: 'https://be.my.stg.enstall.com/api',
  searchApiUrl: 'https://gipis.my.stg.enstall.com/api/v1/search',
};

const msalConfig: MsalConfigEnvironmentModel = {
  auth: {
    baseAuthorityUrl:
      'https://B2CStageEnstallid.b2clogin.com/B2CStageEnstallid.onmicrosoft.com/',
    clientId: 'f43a112c-13a1-4ab7-a192-d1e73f5de046',
    authority: 'B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
    signUpAuthority: 'B2C_1A_NEURON_SIGNUP',
    passwordResetAuthority: 'B2C_1A_SEAMLESSMIGRATION_PASSWORDRESET',
    knownAuthorities: ['B2CStageEnstallid.b2clogin.com'],
    scopes: [
      'https://B2CStageEnstallid.onmicrosoft.com/e927817a-86cc-40b8-b237-13bb1681c3c2/Data.Read',
    ],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
};

const toolLinks: ToolLinksEnvironmentModel = {
  ironridgeProjectsLink: 'https://staging-ridge2.com/projects',
  projectsUrl: 'https://staging-ridge2.com',
  trainingUrl:
    'https://ironridgestage.wpengine.com/resources/on-demand-training/',
  installCertificationUrl:
    'https://ironridgestage.wpengine.com/resources/installer-cert/',
  calculatorUrl: 'https://esdec-acc.quintor.nl/login',
  academyUrl: 'https://esdeclearning.sandbox.myabsorb.eu/',
  onsitePageUrl: 'https://www.esdec.com/{language}/my-esdec/#esdec_onsite',
  onsiteAppUrl: 'https://esdec.web.ensapp.accept.paqt.io',
  chapterUrl: 'https://my.stg.enstall.com/enstall-services/chapter',
  erpUrl: 'https://my.stg.enstall.com/enstall-services/one-flow',
  designAssistantUrl: 'https://usdev1.efda.solar/dashboard',
  ecofastenAcademyUrl: 'https://ecofastensolar.sandbox.myabsorb.com',
  ecofastenInsightUrl: '',
  enstallErpUrl:
    'https://opusflow-git-enstall-sso.preview.opusflow.io/sign-in?provider=enstall',
};

const legislationLinks: LegislationLinksEnvironmentModel = {
  cookiesPolicy: {
    [Client.ENSTALL]: 'https://www.enstall.com/cookies-policy/',
    [Client.ENSTALL_EU]: 'https://www.enstall.com/cookies-policy/',
    [Client.IRONRIDGE]: 'https://www.ironridge.com/cookies-policy/',
    [Client.ESDEC]: 'https://staging.esdec.com/en/cookies/',
    [Client.ECOFASTENSOLAR]: 'https://www.ecofastensolar.com/cookies-policy/',
    ['Panelclaw']: 'https://staging.panelclaw.eu/en/cookie-statement/',
  },
  privacyPolicy: 'https://enstall.com/privacy-policy',
  termsOfUse:
    'https://enstall.com/wp-content/uploads/2024/03/Enstall-Digital-Services-Platform-Terms-of-Use.pdf',
};

export const environment: EnvironmentModel = {
  ...environmentCommon,
  ...environmentCore,
  msalConfig,
  ...toolLinks,
  ...legislationLinks,
};
