import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  combineLatestWith,
  distinctUntilChanged,
  filter,
  switchMap,
  tap,
} from 'rxjs';
import { GaService } from 'src/app/core/services/ga.service';
import { UserService } from 'src/app/core/services/user.service';
import { dropdownLanguages } from '../../core/models/supported-language.model';
import { User } from '../../core/models/user.model';
import { AuthService } from '../../core/services/auth.service';
import { LanguageService } from '../../core/services/language.service';
import { StateService } from '../../core/services/state.service';

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent {
  stateService = inject(StateService);
  languageService = inject(LanguageService);
  authService = inject(AuthService);
  userService = inject(UserService);
  languageOptions = dropdownLanguages;
  gaService = inject(GaService);
  languageControl = new FormControl<string>('');

  private readonly currentUser$ = this.stateService.user$.pipe(
    distinctUntilChanged(
      (prev, curr) => prev.preferredLanguage === curr.preferredLanguage
    ),
    tap((user: User) =>
      this.languageControl.patchValue(
        this.languageService.getSystemLanguageName(user.preferredLanguage ?? '')
      )
    )
  );

  languageSelected$ = this.currentUser$.pipe(
    combineLatestWith(this.languageControl.valueChanges),
    distinctUntilChanged(([, prevLang], [, currLang]) => prevLang === currLang),
    filter(([, formLanguage]) => Boolean(formLanguage)),
    tap(([, formLanguage]) =>
      this.languageService.loadLanguageFile(formLanguage ?? '')
    ),
    switchMap(([user, formLanguage]) => {
      const systemLanguage = this.languageService.getSystemLanguageName(
        formLanguage ?? ''
      );
      this.gaService.sendSelectLanguage(user.epUserUuid ?? '');
      return this.userService.patchUserPreferredLanguage(
        user.epUserUuid ?? '',
        systemLanguage ?? ''
      );
    }),
    tap(user =>
      this.languageService.setCurrentLang(user.preferredLanguage ?? '')
    )
  );
}
