export enum GaCategory {
  EMPTY = '',
  CALCULATOR = 'calculator',
  IRONRIDGE_COURSES = 'courses',
  ESDEC_ACADEMY = 'esdec_academy',
  ESDEC_ONSITE_APP = 'onsite_app',
  ESDEC_ONSITE_PAGE = 'onsite_page',
  ENSTALL_ACADEMY = 'enstall_academy',
  ENSTALL_ERP = 'enstall_erp',
  ECOFASTEN_DESIGN_ASSISTANT = 'design_assistant',
  ECOFASTEN_ACADEMY = 'ecofasten_academy',
  ECOFASTEN_INSIGHT = 'ecofasten_insight',
}
