/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable } from '@angular/core';
import { GaCategory } from '../models/ga-category.model';
import { SubscriptionToolCode } from '../models/subscription.model';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class GaService {
  private readonly languageService = inject(LanguageService);

  sendGipNavigation(
    link: string,
    toolCode: SubscriptionToolCode,
    user_uuid: string
  ) {
    const click_category = this.getGaCategoryByCode(toolCode);
    const data = {
      event: 'gip_navigation',
      click_url: link,
      click_category,
      user_uuid,
      language: this.languageService.getCurrentLanguageCode(),
    };
    this.sendGaData(data);
  }

  sendSelectLanguage(user_uuid: string): void {
    const data = {
      event: 'select_new_language',
      user_uuid,
      language: this.languageService.getCurrentLanguageCode(),
    };
    this.sendGaData(data);
  }

  sendGaData(data: any) {
    (window as any).dataLayer.push(data);
  }

  private getGaCategoryByCode(toolCode: SubscriptionToolCode): GaCategory {
    switch (toolCode) {
      // Ironridge
      case SubscriptionToolCode.ir_da:
        return GaCategory.CALCULATOR;
      case SubscriptionToolCode.ir_ld:
        return GaCategory.IRONRIDGE_COURSES;
      // EcoFasten
      case SubscriptionToolCode.ecofasten_da:
        return GaCategory.ECOFASTEN_DESIGN_ASSISTANT;
      case SubscriptionToolCode.ecofasten_academy:
        return GaCategory.ECOFASTEN_ACADEMY;
      // Esdec
      case SubscriptionToolCode.esdec_calc:
        return GaCategory.CALCULATOR;
      case SubscriptionToolCode.esdec_academy:
        return GaCategory.ESDEC_ACADEMY;
      case SubscriptionToolCode.esdec_onsite:
        return GaCategory.ESDEC_ONSITE_APP;
      // Enstall
      case SubscriptionToolCode.enstall_academy:
        return GaCategory.ENSTALL_ACADEMY;
      case SubscriptionToolCode.enstall_erp:
        return GaCategory.ENSTALL_ERP;
      default:
        return GaCategory.EMPTY;
    }
  }
}
