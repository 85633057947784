import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, concatMap, map, Observable, share, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prd';
import {
  SubscriptionModel,
  SubscriptionStatus,
  SubscriptionToolCode,
} from '../models/subscription.model';
import { User } from '../models/user.model';
import { longPoll } from '../rxjs.util';
import { AuthService } from './auth.service';
import { ErrorHandlerService } from './error-handler.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly http = inject(HttpClient);
  private readonly errorService = inject(ErrorHandlerService);
  private readonly authService = inject(AuthService);
  private readonly stateService = inject(StateService);

  private readonly apiUrl = `${environment.apiUrl}/users`;
  // this gives total long polling time - 600ms * 50 = 30s
  private readonly LONG_POLL_INTERVAL = 600;
  private readonly LONG_POLL_RETRIES = 50;

  getUserByUuid(epUserUuid: string): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/${epUserUuid}`).pipe(
      share(),
      catchError(error => this.errorService.handleError(error))
    );
  }

  getActiveUser(): Observable<User> {
    return this.authService.getUserUuid().pipe(
      concatMap(userUuid =>
        this.getUserByUuid(userUuid!).pipe(
          tap(user => this.authService.setUser(user)),
          catchError(error => this.errorService.handleError(error))
        )
      )
    );
  }

  patchUserPreferredLanguage(
    userUuid: string,
    preferredLanguage: string
  ): Observable<User> {
    return this.http
      .patch<User>(`${this.apiUrl}/${userUuid}`, {
        preferredLanguage,
      })
      .pipe(
        switchMap(user =>
          this.getUserByUuid(user.epUserUuid!).pipe(
            tap(user => this.authService.setUser(user)),
            catchError(error => this.errorService.handleError(error))
          )
        )
      );
  }

  updateUser(user: Partial<User>): Observable<User> {
    return this.http.put<User>(`${this.apiUrl}/${user.epUserUuid}`, user).pipe(
      switchMap(user =>
        this.getUserByUuid(user.epUserUuid ?? '').pipe(
          tap(user => this.authService.setUser(user)),
          catchError(error => this.errorService.handleError(error))
        )
      )
    );
  }

  longPollForUserWhileSubscriptionIsNotInStatus(
    toolCode: SubscriptionToolCode,
    status: SubscriptionStatus = SubscriptionStatus.ACTIVE
  ): Observable<boolean> {
    const source$ = this.getActiveUser().pipe(
      map(user => this.isUserSubscriptionInStatus(user, toolCode, status))
    );
    const stopCondition = (isSubscriptionInStatus: boolean) =>
      !isSubscriptionInStatus;

    return longPoll<boolean>(
      source$,
      stopCondition,
      this.LONG_POLL_INTERVAL,
      this.LONG_POLL_RETRIES
    );
  }

  longPollForUserWhileSubscriptionIsNotInStatusOrNotPresent(
    toolCode: SubscriptionToolCode,
    status: SubscriptionStatus = SubscriptionStatus.ACTIVE
  ): Observable<boolean> {
    const source$ = this.getActiveUser().pipe(
      map(user => {
        const subscriptionIsNotPresent = !user.subscriptions.some(
          subscription => subscription.toolCode === toolCode
        );
        if (subscriptionIsNotPresent) {
          return true;
        }
        return this.isUserSubscriptionInStatus(user, toolCode, status);
      })
    );
    const stopCondition = (isSubscriptionInStatus: boolean) =>
      !isSubscriptionInStatus;

    return longPoll<boolean>(
      source$,
      stopCondition,
      this.LONG_POLL_INTERVAL,
      this.LONG_POLL_RETRIES
    );
  }

  isSubscriptionInStatus(
    toolCode: SubscriptionToolCode,
    status: SubscriptionStatus = SubscriptionStatus.ACTIVE
  ): Observable<boolean> {
    return this.getActiveUser().pipe(
      map(user => user.subscriptions),
      map((subscriptions: SubscriptionModel[]) =>
        this.checkStatus(subscriptions, toolCode, status)
      )
    );
  }

  /**
   * Check if user has a subscription in a specific status
   * Needed for long polling due to the retry interval maybe shorter than API call response time
   * @param user
   * @param toolCode
   * @param status
   */
  isUserSubscriptionInStatus(
    user: User,
    toolCode: SubscriptionToolCode,
    status: SubscriptionStatus = SubscriptionStatus.ACTIVE
  ): boolean {
    return this.checkStatus(user.subscriptions, toolCode, status);
  }

  private checkStatus(
    subscriptions: SubscriptionModel[],
    toolCode: SubscriptionToolCode,
    status: SubscriptionStatus
  ): boolean {
    return (
      subscriptions?.some(
        subscription =>
          subscription.toolCode === toolCode && subscription.status === status
      ) ?? false
    );
  }

  extractInitials(user: User): string {
    return `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(
      0
    )}`.toUpperCase();
  }
}
